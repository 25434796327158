import Vue from 'vue'
import VueRouter from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

Vue.use(VueRouter)
Vue.use(NProgress);


NProgress.configure({
  easing: 'ease',  // 动画方式
  speed: 500,  // 递增进度条的速度
  showSpinner: false, // 是否显示加载ico
  trickleSpeed: 200, // 自动递增间隔
  minimum: 0.3 // 初始化时的最小百分比
})

const routes = [
  {
    path: '/',
    name: '河北荣信政策',
    component: () => import('../views/main.vue'),
    meta: {
      type: 'login',  // 是否需要判断是否登录,这里是需要判断
    }
  },
  {
    path: '/login',
    name: '登录',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue'),
    meta: {
      type: '',  // 是否需要判断是否登录,这里是需要判断
    }
  }
]

const router = new VueRouter({
  routes,
  mode: 'history',
})


router.beforeEach((to,from,next) => {
  // 每次切换页面时，调用进度条
  NProgress.start();
  document.title = to.name
  // 判断该路由是否需要登录权限
  if (to.meta.type === 'login') {
    var token = window.localStorage.getItem('token');
    if (token == "" || token == undefined) {
      next('/login')
    }
  }
  next()
})

router.afterEach(() => {
  // 在即将进入新的页面组件前，关闭掉进度条
  NProgress.done()
})
// 解决菜单跳转相同路径下报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router

